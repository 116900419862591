<template>
    
    <v-container>

        <div class="px-2">
                
            <h1 class="mt-8">{{ $t('Pages') }}</h1>

            <v-breadcrumbs
                :items="breadcrumbs"
                large class="pl-0 pt-2"
            ></v-breadcrumbs>
        
        </div>
        
        <PagesList type="page" @selected="openPage" :show-add-page="true"/>

        <!-- {{ pagesData }} -->

    </v-container>

</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import pages from "@/store/pages/pages" ;
import PagesList from "@/components/pages/List" ;

export default {
    
    components: {
        PagesList
    },
    provide: {
        pages
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;

        const breadcrumbs = ref([
            {
                text: ctx.root.$t('Dashboard'),
                disabled: false,
                exact: true,
                to: '/dashboard',
            },
            {
                text: ctx.root.$t('Pages'),
                disabled: false,
                exact: true,
                to: '/pages',
            },
        ]) ;

        onMounted(() => {
            currentWebsite.setTitle(ctx.root.$t('Pages'));
        });

        const openPage = (page) => {

            console.log("page", page.data());

            ctx.root.$router.push("/pages/"+page.id);
        }

        return {
            app,
            currentWebsite,
            currentUser,
            breadcrumbs,
            openPage
        }
    
    },

};

</script>